import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import Breadcrumbs from '../components/Breadcrumbs';
import ModularBlocks from '../components/ModularBlocks';

const PressReleaseSingleTemplate = ({
  data: {
    datoCmsPressRelease: {
      seoMetaTags,
      title,
      firstPublishedAt,
      bannerImage,
      modularBlocks,
    },
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <Banner
        heading={title}
        firstPublishedAt={firstPublishedAt}
        image={bannerImage}
      />
      <Breadcrumbs
        breadcrumb={{ slug: 'press-releases', text: 'Press Releases' }}
        currentPageTitle={title}
      />
      <ModularBlocks items={modularBlocks} />
    </main>
  </Layout>
);

export const PressReleaseSingleTemplateQuery = graphql`
  query PressReleaseSingleTemplateQuery($id: String!) {
    datoCmsPressRelease(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      firstPublishedAt: meta {
        robotDate: firstPublishedAt
        humanDate: firstPublishedAt(formatString: "DD.MM.YYYY")
      }
      bannerImage {
        ...SubpageBannerImageFragment
      }
      modularBlocks {
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FeaturedEquipmentModularBlockFragment
        ...ImageSliderModularBlockFragment
        ...VideoModularBlockFragment
      }
    }
  }
`;

export default PressReleaseSingleTemplate;
